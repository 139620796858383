import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MailingFormSchema } from "./MailingFormSchema";

//styles
import styles from "./Footer.module.scss";

//images
import {
  arrowRightIco,
  footerLogoImg,
} from "../../ImageConstants/imageConstants";

//commons
import Logo from "../Commons/Logo";
import Input from "../Commons/Inputs/Input/Input";

//api
import { createLead } from "../../API/createBitrixLead";

//Routes
import { PRIVACY_PAGE_ROUTE, pathFC } from "../App/Routes/routesConstants";

//db
import { socialsData } from "../../Db/contactsData";
import { footerMenu, contactsFooterData } from "../../Db/Footer/footerData";

//redux
import { setModalWindowChildrenType, setModalWindowStatus } from "../../ReduxToolkit/App/app";

import OrganizationSchema from "./OrganizationSchema";

const nameFields = {
  email: "email",
};

export const Footer = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(MailingFormSchema(t)),
  });

  const onSubmit = (data) => {
    const obj = {
      email: data.email,
    };
    const type = "mailing";
    createLead(obj, type, dispatch);
  };

  return (
    <>
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.footer__top}>
            <div className={styles.logo}>
              <Logo img={footerLogoImg} footer />
              <span>
                {t("footer.infoBlock.paragraph_1")} <br />
                {t("footer.infoBlock.paragraph_2")} <br />
                {t("footer.infoBlock.paragraph_3")} <br />
                {t("footer.infoBlock.paragraph_4")}
              </span>
            </div>
            <ul className={styles.menu}>
              {footerMenu.map(({ id = 1, url = "/", label = "" }) => (
                <li key={id}>
                  <NavLink
                    className={styles.menu__link}
                    to={pathFC(url)}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t(label)}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className={styles.contacts}>
              <h4 className={styles.title}>{t("footer.titles.contacts")}</h4>
              <ul className={styles.contacts__list}>
                {contactsFooterData?.map(
                  ({
                    id = 1,
                    label = "",
                    url = "/",
                    img = "",
                    alt = "",
                    tel = false,
                    blank,
                  }) => (
                    <li key={id} className={styles.contacts__list_item}>
                      <LazyLoadImage
                        className={styles.contacts__list_img}
                        src={img}
                        alt={alt} />
                      <a
                        href={url}
                        className={`${styles.contacts__list_link} ${
                          tel ? styles.bold : ""
                        }`}
                        target={blank ? "_blank" : "_self"}
                        rel="noreferrer"
                      >
                        {t(label)}
                      </a>
                    </li>
                  )
                )}
              </ul>
              <ul className={styles.social}>
                {socialsData.map(({ id = 1, url = "/", img = "", alt = "" }) => (
                  <li key={id} className={styles.social__item}>
                    <a href={url} target="_blank" className={styles.social__link}>
                    <LazyLoadImage
                      src={img}
                      alt={alt} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.worktime}>
              <h4 className={styles.title}>{t("footer.titles.schedule")}</h4>
              <ul className={styles.worktime__list}>
                <li className={styles.worktime__item}>
                  <span>{t("footer.schedule.days.work_day")}</span>:{" "}
                  {t("footer.schedule.time.work_day")}
                </li>
                <li className={styles.worktime__item}>
                  <span>{t("footer.schedule.days.day_off")}</span>:{" "}
                  {t("footer.schedule.time.day_off")}
                </li>
              </ul>
            </div>
            <div className={styles.footer__top_right}>
              <div className={styles.subscribe}>
                {t("footer.subscribe.descr")}

                <form
                  className={styles.subscribe__form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className={styles.inner__form}>
                    <Input
                      width={{ xl: "100%" }}
                      id={nameFields.email}
                      name={nameFields.email}
                      type="email"
                      placeholder={t("footer.subscribe.inputMail")}
                      required
                      error={errors?.email}
                      register={register}
                      className={styles.subscribe__input}
                    />
                    <button className={styles.subscribe__btn} type="submit">
                      <img src={arrowRightIco} alt="arrow" />
                    </button>
                  </div>
                  <div className={styles.privacy_info}>
                    {t("footer.subscribe.privacy1")}
                    {" "}
                    <NavLink
                      onClick={() => {
                        dispatch(setModalWindowStatus(false));
                        dispatch(setModalWindowChildrenType(""));
                      }}
                      to={pathFC(`${PRIVACY_PAGE_ROUTE}`)}
                    >
                      {t("footer.subscribe.privacy2")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer__bottom}>
          <div className="container">
            <p className={styles.footer__bottom_text}>
              <span>{t("footer.bottom.span")}</span>
              {t("footer.bottom.text")}
            </p>
          </div>
        </div>
      </footer>
      <OrganizationSchema />
    </>
  );
};
